import { Injectable } from '@angular/core';
import { CommonApiService } from '../../shared/services/common-api.service';
import { map } from 'rxjs/operators';
import { UtilsService } from '../../shared/services/utils.service';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { PrivateDataShare } from '../../shared/services/privateDataShare.service';
import { environment } from '../../../../default/environments/environment';
const CryptoJS = require("crypto-js");

@Injectable({
  providedIn: 'root',
})
export class DasboardService {
  policyNumber = this.utilsService.getPolicyNumber();
  tenant = this.route.parent.snapshot.params.tenant;

  constructor(
    private http: CommonApiService,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    private privateDataShare: PrivateDataShare
  ) { }

  // Used to get agency, billing, coverage, discounts, mortgagee, policy form, Primary name insured details and risk address
  getPolicyInfoByPolicyNumber() {
    const tenant = this.tenant
      ? this.tenant
      : this.route.snapshot.params.tenant;
    this.policyNumber = this.privateDataShare.getPolicyNumber();
    let today = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    const data = {
      "accountCode": tenant,
      "policyNumber": this.policyNumber,
      "asOfDate": today
    }
    const key = {
      payload: atob(environment.securityCode.policyNumber.inputKey)
    }
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key.payload).toString();
    const encryptedData = {
      data: ciphertext
    }
    return this.http.privatePost(`getPolicyInfoByPolicyNumber`, encryptedData, true)
      .pipe(
        map((data) => {
          return data.out;
        })
      );
  }

  getPaymentSummary() {
    this.policyNumber = this.privateDataShare.getPolicyNumber();
    const key = {
      payload: atob(environment.securityCode.policyNumber.inputKey)
    }

    const data = {
      "accountCode": this.tenant,
      "policyNumber": this.policyNumber
    }

    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key.payload).toString();
    const encryptedData = {
      data: ciphertext
    }
    return this.http.privatePost(`getPaymentSummary`, encryptedData, true)
      .pipe(
        map((data) => {
          return data.out;
        })
      );
  }

  getBillingInfo(policyNumber) {
    let policyDetails = []
    policyDetails = this.privateDataShare.getPolicyDetails().PolicySummary;
    var policyStatus = policyDetails.find(resp => resp.policyDetails.policyNumber == policyNumber).policyDetails.status
    const data = {
      "accountCode": this.tenant,
      "policyNumber": policyNumber
    }
    const key = {
      payload: atob(environment.securityCode.policyNumber.inputKey)
    }
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key.payload).toString();
    const encryptedData = {
      data: ciphertext
    }
    return this.http.privatePost(`getBillingPolicyInfo`, encryptedData, true)
      .pipe(
        map((data) => {
          var resp = data;
          var billingInfo = {};
          billingInfo['dueDate'] = 'No Payment Due';
          billingInfo['nextPaymentDue'] = '0.00';
          billingInfo['remainingBalance'] = '0.00';
          billingInfo['billTo'] = '';
          billingInfo['pcanDueDate'] = 'No Payment Due';
          billingInfo['policyStatus'] = policyStatus;

          //
          var termsLength = resp.length;
          let currentTermPaymentDetails;
          let renewalTermPaymentDetails;
          for (var paymentInfo of resp) {
            if (paymentInfo.termType == 'Current') {
              currentTermPaymentDetails = paymentInfo;
            } else if (paymentInfo.termType == 'Future') {
              renewalTermPaymentDetails = paymentInfo;
            }
          }
          currentTermPaymentDetails = ((!currentTermPaymentDetails) && (renewalTermPaymentDetails)) ? renewalTermPaymentDetails : currentTermPaymentDetails;
          if (currentTermPaymentDetails) {
            var paymentList = currentTermPaymentDetails.policy.policyInstallments.InstallmentInfo;
            billingInfo['billTo'] = currentTermPaymentDetails.policy.billPlan.billType;
            billingInfo['paymentPlan'] = currentTermPaymentDetails.policy.billPlan.name;
            billingInfo['nextPaymentDue'] = currentTermPaymentDetails.policy.nextAmountDue;
            billingInfo['remainingBalance'] = currentTermPaymentDetails.policy.payOffBalance;
            if(currentTermPaymentDetails.policy.pcanDueDate != null)
            {
              billingInfo['pcanDueDate'] = this.datepipe.transform(
                currentTermPaymentDetails.policy.pcanDueDate,'MM/dd/yyyy');
            }
            for (var i = 0, j = 0; i < paymentList.length; i++) {
              if (paymentList[i]['status'] != 'Failed') {
                if (j == 0 && paymentList[i]['balanceDue'] != '0.00') {
                  //billingInfo['nextPaymentDue'] = paymentList[i]['balanceDue'];
                  billingInfo['dueDate'] = this.datepipe.transform(
                    paymentList[i]['dueDate'],
                    'MM/dd/yyyy'
                  );
                  j++;
                }
                // billingInfo['remainingBalance'] = (
                //   parseFloat(billingInfo['remainingBalance']) +
                //   parseFloat(paymentList[i]['balanceDue'])
                // ).toFixed(2);
                //case when current term is paid in full - checking the renewal term
                if (billingInfo['remainingBalance'] == 0.0 && termsLength > 1) {
                  console.log("in renewal term calculation")
                  billingInfo['dueDate'] = 'No Payment Due';
                  billingInfo['nextPaymentDue'] = '0.00';
                  billingInfo['remainingBalance'] = '0.00';
                  billingInfo['billTo'] = '';
                  
                  var paymentList = renewalTermPaymentDetails.policy.policyInstallments.InstallmentInfo;
                  billingInfo['billTo'] = renewalTermPaymentDetails.policy.billPlan.billType;
                  billingInfo['paymentPlan'] = renewalTermPaymentDetails.policy.billPlan.name;
                  billingInfo['nextPaymentDue'] = renewalTermPaymentDetails.policy.nextAmountDue;
                  billingInfo['remainingBalance'] = renewalTermPaymentDetails.policy.payOffBalance;
                  
                  for (var i = 0, j = 0; i < paymentList.length; i++) {
                    if (paymentList[i]['status'] != 'Failed') {
                      if (j == 0 && paymentList[i]['balanceDue'] != '0.00') {
                        // billingInfo['nextPaymentDue'] =
                        //   paymentList[i]['balanceDue'];
                        billingInfo['dueDate'] = this.datepipe.transform(
                          paymentList[i]['dueDate'],
                          'MM/dd/yyyy'
                        );
                        j++;
                      }
                      // billingInfo['remainingBalance'] = (
                      //   parseFloat(billingInfo['remainingBalance']) +
                      //   parseFloat(paymentList[i]['balanceDue'])
                      // ).toFixed(2);

                    }
                  }
                }
              }
            }
          }
          return billingInfo;
        })
      );
  }

  getPolicyDocumentsMetadata() {
    this.policyNumber = this.privateDataShare.getPolicyNumber();
    const key = {
      payload: atob(environment.securityCode.policyNumber.inputKey)
    }

    const data = {
      "accountCode": this.tenant,
      "policyNumber": this.policyNumber
    }

    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key.payload).toString();
    const encryptedData = {
      data: ciphertext
    }
    return this.http.privatePost(`getPolicyDocumentsMetadata`, encryptedData, true)
      .pipe(
        map((data) => {
          return data.out;
        })
      );
  }

  downloadDocument(documentId: string) {
    const tenant = this.tenant
      ? this.tenant
      : this.route.snapshot.params.tenant;
    return this.http
      .privateGet(
        `downloadDocument?documentId=${documentId}&accountCode=${tenant}`,
        null,
        true
      )
      .pipe(
        map((data) => {
          return data.out;
        })
      );
  }

  // API to get card info details
  getCardInfo(infoType) {
    const tenant = this.tenant
      ? this.tenant
      : this.route.snapshot.params.tenant;
    return this.http
      .privateGet(
        `getCardInfo?infoType=${infoType}&accountCode=${tenant}`,
        null,
        false
      )
      .pipe(
        map((data) => {
          var styling = this.utilsService.getItemfromSession('themeObject');

          data.out = data.out.replace(
            /codeobjects-header-font-size/gi,
            styling.header['font-size']
          );
          data.out = data.out.replace(
            /codeobjects-header-font-weight/gi,
            styling.header['font-weight']
          );
          data.out = data.out.replace(
            /codeobjects-header-font-family/gi,
            styling.header['font-family']
          );
          data.out = data.out.replace(
            /codeobjects-content-font-size/gi,
            styling.content['font-size']
          );
          data.out = data.out.replace(
            /codeobjects-content-font-weight/gi,
            styling.content['font-weight']
          );
          data.out = data.out.replace(
            /codeobjects-content-font-family/gi,
            styling.content['font-family']
          );
          data.out = data.out.replace(
            /codeobjects-contactHeader-font-family/gi,
            styling.contactHeader['font-family']
          );
          data.out = data.out.replace(
            /codeobjects-contactHeader-font-size/gi,
            styling.contactHeader['font-size']
          );
          data.out = data.out.replace(
            /codeobjects-contactHeader-font-weight/gi,
            styling.contactHeader['font-weight']
          );
          data.out = data.out.replace(
            /codeobjects-contactContent-font-family/gi,
            styling.contactContent['font-family']
          );
          data.out = data.out.replace(
            /codeobjects-contactContent-font-size/gi,
            styling.contactContent['font-size']
          );
          data.out = data.out.replace(
            /codeobjects-contactContent-font-weight/gi,
            styling.contactContent['font-weight']
          );

          return data.out;
        })
      );
  }

  findAgents(agencyName) {
    const tenant = this.tenant
      ? this.tenant
      : this.route.snapshot.params.tenant;
    return this.http
      .privateGet(
        `findAgents?agencyName=${agencyName}&accountCode=${tenant}`,
        null,
        false
      )
      .pipe(
        map((data) => {
          return data.out;
        })
      );
  }

  findAgentsTemp(agency) {
    // this fn is temporary...please delete
    const tenant = this.tenant
      ? this.tenant
      : this.route.snapshot.params.tenant;
    let url = `findAgents?accountCode=sfi`;
    if (agency && agency.agencyName)
      url = `${url}&agencyName=${agency.agencyName}`;
    if (agency && agency.city) url = `${url}&city=${agency.city}`;
    if (agency && agency.zipCode) url = `${url}&zipCode=${agency.zipCode}`;
    return this.http.privateGet(url, null, false).pipe(
      map((data) => {
        return data.out;
      })
    );
  }

  // API to get all the claims under a specified policy
  getClaimsForPolicy(policyNumber) {
    // this.policyNumber = this.utilsService.getPolicyNumber();

    const data = {
      "accountCode": this.tenant,
      "policyNumber": policyNumber
    }
    const key = {
      payload: atob(environment.securityCode.policyNumber.inputKey)
    }
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key.payload).toString();
    const encryptedData = {
      data: ciphertext
    }
    return this.http.privatePost(`getClaimsForPolicy`, encryptedData, true)
      .pipe(
        map((data) => {
          return data.out;
        })
      );
  }
  // getClaimLength(policyNumber) {
  //   return this.http.privateGet(`getClaimsForPolicy?policyNumber=${policyNumber}&accountCode=${this.tenant}`, null, true)
  //   .pipe(
  //     map(data=> {
  //       if (data.out != null){
  //         return data.out.ClaimSummary.length;
  //       }else{
  //         return 0;
  //       }
  //        })
  //   )
  // }
  // this.dasboardService.getClaimsForPolicy(policyNumber).subscribe((resp) => {
  //   if (resp != null) {

  //   return resp.ClaimSummary.length;
  //   }else{
  //     return 0;
  //   }
  // });

  //API to get policy details / PolicySummary
  getPoliciesByUser() {
    const tenant = this.tenant
      ? this.tenant
      : this.route.snapshot.params.tenant;
    return this.http
      .privateGet(`getPoliciesByUser?accountCode=${tenant}`, null, true)
      .pipe(
        map((data) => {
          return data.out;
        })
      );
  }

  getCustomerByID() {
    return this.http
      .privateGet(`getCustomerByID?accountCode=${this.tenant}`, null, true)
      .pipe(
        map((data) => {
          return data.out;
        })
      );
  }

  updateCustomerUser(data) {
    data.accountCode = this.tenant;
    return this.http.privatePut('updateCustomerUser', data, true);
  }

  uploadConfiguration(data) {
    data.accountCode = this.tenant;
    return this.http.privatePost(`uploadConfiguration`, data, true);
  }

  makeCreditCardPayment(data) {
    data.accountCode = this.tenant;
    return this.http.privatePost(`makeCreditCardPayment`, data, true);
  }

  makeBankAccountPayment(data) {
    data.accountCode = this.tenant;
    return this.http.privatePost(`makeBankAccountPayment`, data, true);
  }

  removePolicy(data) {
    data.accountCode = this.tenant;
    return this.http.privatePost('removePolicyFromUserAccount', data, true);
  }

  getAccountConfiguration(loaderFlag: boolean = true) {
    const tenant = this.tenant
      ? this.tenant
      : this.route.snapshot.params.tenant;
    return this.http.privateGet(
      `accountConfiguration?accountCode=${tenant}`,
      null,
      loaderFlag
    );
  }

  changePassword(data) {
    data.accountCode = this.tenant;
    // return this.http.changePasswordPut(`changePassword`, data, oldPwd, true);
    return this.http.changePasswordPut(`changePassword`, data, true); 
  }

  recordPayment(data) {
    data.accountCode = this.tenant;
    return this.http.privatePost(`recordPayment`, data, true);
  }

  getCatastrophes(address, date) {
    return this.http
      .privateGet(
        `getCatastrophes?address={"county":"${address.county}","stateCode":"${address.stateCode
        }", "zip":"${address.zipCode
        }"}&lossDate=${date.toLocaleDateString('en-US')}&accountCode=${this.tenant}`,
        null,
        true
      )
      .pipe(
        map((data) => {
          return data.out;
        })
      );
  }

  getCausesOfLoss() {
    return this.http.privateGet(
      `getCausesOfLoss?accountCode=${this.tenant}`,
      null,
      true
    );
  }

  createClaim(data) {
    data.accountCode = this.tenant;
    return this.http.privatePost('createClaim', data, true);
  }

  addPolicyToUserAccount(data) {
    data.accountCode = this.tenant
      ? this.tenant
      : this.route.snapshot.params.tenant;
    return this.http.privatePost(`addPolicyToUserAccount`, data, true);
  }

  closeAccount() {
    return this.http
      .privatePost(`closeUserAccount?accountCode=${this.tenant}`, null, true)
      .pipe(
        map((data) => {
          return data.out;
        })
      );
  }

  uploadClaim(data) {
    // data.accountCode = this.tenant;
    return this.http.privateUpload('uploadclaimsdocuments', data, true);
  }

  getClaimDocumentsMetadata(claimID) {
    return this.http
      .privateGet(
        `getClaimDocumentsMetadata?claimNumber=${claimID}&accountCode=${this.tenant}`,
        null,
        true
      )
      .pipe(
        map((data) => {
          return data.out;
        })
      );
  }

  getBillingPolicyInfo() {
    this.policyNumber = this.privateDataShare.getPolicyNumber();
    const data = {
      "accountCode": this.tenant,
      "policyNumber": this.policyNumber
    }
    const key = {
      payload: atob(environment.securityCode.policyNumber.inputKey)
    }
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key.payload).toString();
    const encryptedData = {
      data: ciphertext
    }
    return this.http.privatePost(`getBillingPolicyInfo`, encryptedData, true)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
